import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import constantes from "../recursos/constantes";

const OBTENER_OPCIONES_MENU = loader(
  "../graphQL/query/menu/obtener_menu_opciones.graphql"
);

const { nombreDeCookie } = constantes;

const useMenuService = () => {
  const dispatch = useDispatch();
  const cookie = useCookies([nombreDeCookie]);
  const estadoApp = useSelector((state) => state);

  const [
    obtenerOpcionesMenuGraph,
    { data: dataMO, loading: loadingMO, error: errorMO },
  ] = useLazyQuery(OBTENER_OPCIONES_MENU, { fetchPolicy: "network-only" });

  const obtenerOpcionesMenu = () => {
    const cookieValue = cookie[0][nombreDeCookie];

    if (cookieValue && cookieValue.access_token && cookieValue.Usuario) {
      obtenerOpcionesMenuGraph({
        variables: {
          numeroTelefono: cookieValue.Usuario,
          token: cookieValue.access_token,
        },
      });
      dispatch({
        type: "BORRAR",
        indice: "opcionesMenu",
      });
    }
  };

  const canAccess = (objetoRuta) => {
    const data = estadoApp.opcionesMenu;
    if (data && objetoRuta) {
      const opcionesMenu = data;
      const puedeEntrar = opcionesMenu[objetoRuta.propiedad] || false;
      return puedeEntrar;
    }
    return false;
  };

  const respuestaSolicitud = (rutasQueDisparanConsulta, location) => {
    if (
      !loadingMO &&
      dataMO &&
      dataMO.valida_menu_opciones &&
      dataMO.valida_menu_opciones.dato
    ) {
      dispatch({
        type: "AGREGAR",
        valor: dataMO.valida_menu_opciones.dato,
        indice: "opcionesMenu",
      });
      const rutaCompleta = `${location.pathname}${location.search}`;
      if (rutasQueDisparanConsulta.some((item) => item.ruta === rutaCompleta)) {
        const objetoRuta = rutasQueDisparanConsulta.find(
          (item) => item.ruta === location.pathname
        );

        return canAccess(objetoRuta);
      }
      return true;
    }
    return true;
  };

  return {
    obtenerOpcionesMenu,
    respuestaSolicitud,
    loadingMO,
    errorMO,
    dataMO,
  };
};

export default useMenuService;
