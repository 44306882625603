import styled from "styled-components";

export const CajaResumenContenedor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 35px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    padding: 45px 2px;
  }

  margin-bottom: 25px !important;
`;

export const Icono = styled.div`
  margin-bottom: 16px;
  border: 1.7px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px 25px;
  display: flex;
  justifycontent: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
`;

export const Mensaje = styled.div`
  text-align: center;
  font-family: var(--fuente-proxima-regular);

  h3 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 25px;
  }

  p {
    font-size: 14px;
    color: #666666;
  }

  @media (max-width: 550px) {
    p {
      padding: 0 80px;
    }
  }
`;
