function cortarTexto(texto, maxLength = 15) {
  if (texto.length > maxLength) {
    return `${texto.slice(0, maxLength)}...`;
  }
  return texto;
}

function separarNombre(nombre) {
  const splitNombre = nombre.trim().split(/\s+/);
  const [primerNom, segundoNom] = splitNombre;
  const numeroNombre = splitNombre.length;

  let usuarioFinal = "";
  if (numeroNombre === 0) {
    usuarioFinal = "usuario";
  } else if (numeroNombre === 1) {
    usuarioFinal = primerNom;
  } else if (numeroNombre >= 2) {
    usuarioFinal = `${primerNom} ${segundoNom}`;
  }

  return usuarioFinal;
}

export default function useNombreUsuario(objetoCookie, estadoApp) {
  let nombreFinal = "";
  if (typeof objetoCookie === "string") {
    const usuarioFinal = separarNombre(objetoCookie);

    nombreFinal = `Hola, ${cortarTexto(usuarioFinal)}`;
  } else {
    let usuario =
      objetoCookie && objetoCookie.NombreAsegurado
        ? objetoCookie.NombreAsegurado
        : "usuario";

    if (estadoApp.NombreUsuarioPerfil && estadoApp.NombreUsuarioPerfil.data) {
      usuario = estadoApp.NombreUsuarioPerfil.data;
    }

    const usuarioFinal = separarNombre(usuario);

    nombreFinal = `Hola, ${cortarTexto(usuarioFinal)}`;
  }

  return nombreFinal;
}
