/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { v4 } from "uuid";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  PantallaFondoGris,
  ContenedorPolizas,
  TituloMisPolizas,
  MensajePequeno,
} from "./PantallaInicio.styled";
import EncabezadoPolizasSiniestradas from "../../encabezado-polizas-siniestradas";
import BarraAlerta from "../../barra-alerta";
import IndicadorCarga from "../../indicador-carga";
import CajaResumen from "../../caja-resumen";
import useValidateLogin from "../../../utils/useValidateLogin";
import useRedirect from "../../../utils/useRedirect";
import MenuBottomComponente from "../../menu-bottom";
import { validaDispositivoCelular } from "../../../utils/validaDispositivo";
import { Pantalla } from "../../componentes-styled-compartidos/Pantalla.styled";
import EncabezadoSimple from "../../encabezado-simple";
import {
  EnvolvedorPantallaPolizas,
  PieDePaginaMisPolizas,
} from "../../pantalla-mis-polizas/pantalla-mis-polizas-componente/PantallaMisPolizas.styled";
import constantes from "../../../recursos/constantes";
import showConfig from "../../../utils/configs"; // solo para demo keycloak
import Boton from "../../boton";
import CajaResumenSinPoliza from "../../caja-resumen/caja-resumen-componente/CajaResumenSinPoliza";

const OBTENER_INICIO = loader(
  "../../../graphQL/query/poliza/obtener_inicio.graphql"
);

const diccionario = {
  titulo: "Bienvenido",
  descripcionPantalla:
    'Descarga tu póliza, realiza pagos desde "Mis Pólizas" o realiza seguimiento de tus reportes desde "Mis Siniestros"',
  descripcionPantallaSinPoliza:
    "Registra tus pólizas para poder reportar siniestros, realizar pagos, dar seguimiento a tus reportes.",
  mensajeDeErrorDefault: "Ocurrió un error.",
  reportarSiniestro: "Reportar siniestro",
  mensajeSugerirCambioContrasena:
    "Por seguridad, te recomendamos cambiar tu contraseña",
  etiquetaBotonAgregar: "Agregar póliza",
};

const { nombreDeCookie } = constantes;

const PantallaInicio = () => {
  const [cargando, asignarValorCargando] = useState(true);
  const dispatch = useDispatch();
  const estadoApp = useSelector((state) => state);
  const { redirectRoot, redirect } = useRedirect();
  const { redirect: redirectRegistroPoliza } = useRedirect("/registro-poliza");
  const { validateUser, user: usuario } = useValidateLogin();
  // original
  // if (!validateUser || !usuario) {
  //   redirectRoot();
  // }

  if (!showConfig.showKeycloak) {
    if (!validateUser || !usuario) {
      redirectRoot();
    }
  }

  const [cookie, establecerCookie] = useCookies([nombreDeCookie]);

  const [mostrarBarraAlerta, asignarValorMostrarBarraAlerta] = useState(false);
  const [mensajeAlerta, asignarValorMensajeAlerta] = useState(
    diccionario.mensajeDeErrorDefault
  );

  const [numeroPolizasAUTR, asignarValorNumeroPolizasAUTR] = useState(0);
  const [numeroPolizasDAN, asignarValorNumeroPolizasDAN] = useState(0);
  const [numeroPolizasGMM, asignarValorNumeroPolizasGMM] = useState(0);

  //  const [numeroSiniestrosDAN, asignarValorNumeroSiniestrosDAN] = useState(0);
  const [numeroSiniestrosAUTR, asignarValorNumeroSiniestrosAUTR] = useState(0);

  // const usuario =
  //   objetoCookie && objetoCookie.Usuario ? objetoCookie.Usuario : "0";

  // ORIGINAL
  const { data, loading, error } = useQuery(OBTENER_INICIO, {
    variables: { telefono: usuario },
    fetchPolicy: "cache-and-network",
  });

  const [verMenuInferior, setVerMenuInferior] = useState(false);
  const [mostrarAlertaCambioContrasena, setMostrarAlertaCambioContrasena] =
    useState(false);
  const [verSinAcceso, setVerSinAcceso] = useState(false);
  const [verPolizaEliminada, setVerPolizaEliminada] = useState(false);

  useEffect(async () => {
    setVerMenuInferior(validaDispositivoCelular());
  }, []);

  useEffect(() => {
    const objetoCookie = cookie[nombreDeCookie];
    if (objetoCookie && objetoCookie.Usuario && objetoCookie.access_token) {
      const validado = estadoApp.usuarioValidado;
      if (!validado) {
        const fromLogin = localStorage.getItem("fromLogin");

        if (fromLogin) {
          localStorage.removeItem("fromLogin");
          setMostrarAlertaCambioContrasena(true);
          setTimeout(() => {
            setMostrarAlertaCambioContrasena(false);
          }, 15000);
        }
      }
    }

    // Validar si mostramos la alerta de sin acceso
    if (estadoApp && estadoApp.mostrarCintaSinAcceso) {
      setVerSinAcceso(true);
      dispatch({
        type: "BORRAR",
        indice: "mostrarCintaSinAcceso",
      });
      setTimeout(() => {
        setVerSinAcceso(false);
      }, 10000);
    }
    
    // validar si mostramos la alerta de poliza eliminada y que ya no queda ninguna
    if (estadoApp && estadoApp.mostrarVentanaPolizaEliminada) {
      setVerPolizaEliminada(true);
      dispatch({
        type: "BORRAR",
        indice: "mostrarVentanaPolizaEliminada",
      });

      setTimeout(() => {
        setVerPolizaEliminada(false);
      }, 10000);
    }
  }, []);

  useEffect(() => {
    if (!loading && data && data.cobranza_consultaHome) {
      asignarValorCargando(false);
      if (!data.cobranza_consultaHome.dato) {
        asignarValorNumeroPolizasAUTR(0);
        asignarValorNumeroPolizasDAN(0);
        asignarValorNumeroSiniestrosAUTR(0);
        asignarValorNumeroPolizasGMM(0);
        return;
      }
      asignarValorNumeroPolizasAUTR(
        data.cobranza_consultaHome.dato.totalPolizasSiniestros
      );
      asignarValorNumeroPolizasDAN(
        data.cobranza_consultaHome.dato.totalPolizasDanos
      );
      asignarValorNumeroSiniestrosAUTR(
        data.cobranza_consultaHome.dato.totalSiniestrosAutos
      );
      asignarValorNumeroPolizasGMM(
        data.cobranza_consultaHome.dato.totalPolizasGmm
      );
      dispatch({
        type: "AGREGAR",
        valor: data.cobranza_consultaHome.dato.totalPolizasGmm,
        indice: "consultaHome",
      });
      /*
      asignarValorNumeroSiniestrosDAN(
        data.cobranza_consultaHome.dato.totalSiniestrosDanos
      );
      */
    }
    if (loading) {
      asignarValorCargando(true);
    }
    if (!loading) {
      asignarValorCargando(false);
    }
    if (error) {
      asignarValorMensajeAlerta(error);
    }
  }, [data, loading]);

  return (
    <EnvolvedorPantallaPolizas key={v4()}>
      {cargando ? <IndicadorCarga /> : null}

      {!verMenuInferior && (
        <EncabezadoPolizasSiniestradas mostrarMenu={!verMenuInferior} />
      )}
      {verMenuInferior && <EncabezadoSimple />}
      <Pantalla style={{ "overflow-y": "auto" }}>
        <BarraAlerta
          etiqueta="No tienes autorización para acceder a esta sección"
          mostrarAlerta={verSinAcceso}
          manejarCierre={() => {
            setVerSinAcceso(false);
          }}
          estilo="error"
          posicionAbsoluta
        />
        <BarraAlerta
          etiqueta="Tu póliza se ha eliminado correctamente."
          mostrarAlerta={verPolizaEliminada}
          manejarCierre={() => {
            setVerPolizaEliminada(false);
          }}
          estilo="exitoso"
          posicionAbsoluta
        />
        <BarraAlerta
          etiqueta={mensajeAlerta}
          mostrarAlerta={mostrarBarraAlerta}
          manejarCierre={() => {
            asignarValorMostrarBarraAlerta(false);
          }}
          estilo="error"
          posicionAbsoluta
        />
        <BarraAlerta
          etiqueta={diccionario.mensajeSugerirCambioContrasena}
          mostrarAlerta={mostrarAlertaCambioContrasena}
          manejarCierre={() => {
            setMostrarAlertaCambioContrasena(false);
          }}
          estilo="alerta"
          posicionAbsoluta
        />
        <ContenedorPolizas polizas={0}>
          <TituloMisPolizas id="titulo">{diccionario.titulo}</TituloMisPolizas>
            {!loading &&
              numeroPolizasAUTR === 0 &&
              numeroPolizasDAN === 0 &&
              numeroPolizasGMM === 0 && (
                <>
                  <MensajePequeno id="mensajePequeno">
                    {diccionario.descripcionPantallaSinPoliza}
                  </MensajePequeno>
                  <CajaResumenSinPoliza />
                  <Boton
                    etiqueta={diccionario.etiquetaBotonAgregar}
                    tema="estandar"
                    enClick={() => {
                      redirectRegistroPoliza({ paginaAnterior: "/" });
                    }}
                  />
                </>
              )}

            {!loading &&
              (numeroPolizasAUTR > 0 ||
              numeroPolizasDAN > 0 ||
              numeroPolizasGMM > 0) && (
                <>
                  <MensajePequeno id="mensajePequeno">
                    {diccionario.descripcionPantalla}
                  </MensajePequeno>
                  <CajaResumen
                    numeroAUTR={numeroPolizasAUTR}
                    numeroDAN={numeroPolizasDAN}
                    numeroGMM={numeroPolizasGMM}
                    nombre="Mis pólizas"
                    direccion="/mis-polizas"
                    textoDetalle="Ver pólizas"
                    icono="Poliza"
                  />
                  <CajaResumen
                    numeroAUTR={numeroSiniestrosAUTR}
                    numeroDAN={null}
                    nombre="Mis siniestros"
                    direccion="/polizas-siniestradas"
                    textoDetalle="Ver siniestros"
                    icono="Auto"
                  />
                </>
              )
            }
        </ContenedorPolizas>
      </Pantalla>
      <PieDePaginaMisPolizas />
      {verMenuInferior && <MenuBottomComponente />}
    </EnvolvedorPantallaPolizas>
  );
};
export default PantallaInicio;
