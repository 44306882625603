import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 } from "uuid";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  EnvolvedorPantalla,
  Pantalla,
} from "../../componentes-styled-compartidos/Pantalla.styled";
import IndicadorCarga from "../../indicador-carga";
import CampoTexto from "../../campo-texto";
import { diccionario } from "./config";
import {
  MensajeError,
  SeparadorEspacio,
  Titulo,
} from "./PantallaRegistroUsuario.styled";
import FormularioContrasena from "../../pantalla-contrasena-cambiar/pantalla-contrasena-cambiar-componente/FormularioContrasena";
import { BarraAlerta, Encabezado } from "../..";
import useAccionesLog from "../../../utils/useAccionesLog";
import { validarContrasenia, validarTelefono } from "./validaciones";
import { validarNombre } from "../../../helpers/validaciones/validaciones";
import Constantes from "../../../recursos/constantes";

const REGISTRAR_USUARIOGENERAL = loader(
  "../../../graphQL/mutation/seguridad/seguridad_registrarUsuarioGeneral.graphql"
);

const INICIAR_SESION = loader(
  "../../../graphQL/mutation/seguridad/iniciar_sesion.graphql"
);

const { nombreDeCookie } = Constantes;

export default function PantallaRegistroUsuarioV2() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { runCancelLog, runSuccesLog } = useAccionesLog("");

  const estadoApp = useSelector((estado) => estado);
  const [cookie, establecerCookie] = useCookies([nombreDeCookie]);
  const objetoCookie = cookie[nombreDeCookie];
  const usuario =
    objetoCookie && objetoCookie.Usuario ? objetoCookie.Usuario : "0";

  const [usuarioRegistro, asignarUsuarioRegistro] = useState({});

  const [cargando, asignarCargando] = useState(false);
  const [mensajeAlerta, asignarMensajeAlerta] = useState(
    diccionario.mensajeDeErrorDefault
  );
  const [mostrarBarraAlerta, asignarMostrarBarraAlerta] = useState(false);

  const [focoNombre, asignarFocoNombre] = useState("");
  const [allowNombre, asignarAllowNombre] = useState(false);
  const [errorNombre, asignarErrorNombre] = useState("");

  const [focoApellido, asignarFocoApellido] = useState("");
  const [allowApellido, asignarAllowApellido] = useState(false);
  const [errorApellido, asignarErrorApellido] = useState("");

  const [focoTelefono, asignarFocoTelefono] = useState("");
  const [allowTelefono, asignarAllowTelefono] = useState(false);
  const [errorTelefono, asignarErrorTelefono] = useState("");

  const [focoContrasena, asignarFocoContrasena] = useState("");
  const [allowContrasena, asignarAllowContrasena] = useState(false);

  const [focoConfirmarContrasena, asignarFocoConfirmarContrasena] =
    useState("");
  const [allowConfirmarContrasena, asignarAllowConfirmarContrasena] =
    useState(false);

  if (objetoCookie && objetoCookie.Usuario && objetoCookie.access_token) {
    const validado = estadoApp.usuarioValidado;
    if (!validado) {
      history.push("/registro-usuario-sms", {
        telefono: usuario,
      });
    }
  }

  const valores = {
    nombre: "",
    apellido: "",
    telefono: "",
    contrasenia: "",
    confirmarContrasenia: "",
  };

  // TODO: Quitar el context de la query
  const [
    registrarUsuarioGeneral,
    {
      loading: loadingRegistroUsuario,
      error: errorRegistroUsuario,
      data: dataRegistroUsuarioGeneral,
    },
  ] = useLazyQuery(REGISTRAR_USUARIOGENERAL, {
    fetchPolicy: "no-cache",
  });

  const [iniciarSesion, { loading, error, data }] = useMutation(INICIAR_SESION);

  const enfocarError = (codigo) => {
    switch (codigo) {
      case "nombre":
        asignarFocoNombre("error");
        break;
      case "apellido":
        asignarFocoApellido("error");
        break;
      case "telefono":
        asignarFocoTelefono("error");
        break;
      case "contrasena":
        asignarFocoContrasena("error");
        break;
      case "confirmarContrasena":
        asignarFocoConfirmarContrasena("error");
        break;
      default:
        asignarCargando(false);
        asignarMensajeAlerta(diccionario.errores.errorGenerico);
        asignarMostrarBarraAlerta(true);
        break;
    }
  };

  const limpiarFocosAllow = () => {
    asignarFocoNombre("");
    asignarAllowNombre(false);
    asignarErrorNombre("");

    asignarFocoApellido("");
    asignarAllowApellido(false);
    asignarErrorApellido("");

    asignarFocoTelefono("");
    asignarAllowTelefono(false);
    asignarErrorTelefono("");

    asignarFocoContrasena("");
    asignarAllowContrasena(false);

    asignarFocoConfirmarContrasena("");
    asignarAllowConfirmarContrasena(false);
  };

  const esVacio = (valor) => valor.length === 0;

  const esCierto = (valor) => valor === true;

  const alCambiarNombre = (evento) => {
    if (evento) {
      const valor = evento.target.value;
      valores.nombre = valor;
    }
  };

  const alCambiarApellido = (evento) => {
    if (evento) {
      const valor = evento.target.value;
      valores.apellido = valor;
    }
  };

  const alCambiarTelefono = (evento) => {
    if (evento) {
      const valor = evento.target.value;
      valores.telefono = valor;
    }
  };

  const alDesenfocarNombre = (evt) => {
    if (evt) {
      const valor = evt.target.value;
      valores.nombre = valor;
    }
  };

  const alDesenfocarApellido = (evt) => {
    if (evt) {
      const valor = evt.target.value;
      valores.apellido = valor;
    }
  };

  const alDesenfocarTelefono = (evt) => {
    if (evt) {
      const valor = evt.target.value;
      valores.telefono = valor;
    }
  };

  const hacerValidaciones = (evt) => {
    limpiarFocosAllow();
    const { nombre, apellido, telefono } = valores;
    const { nuevaContrasena, repetirNuevaContrasena } = evt;

    valores.contrasenia = nuevaContrasena;
    valores.confirmarContrasenia = repetirNuevaContrasena;

    asignarUsuarioRegistro(valores);

    validarNombre(nombre, diccionario.errores).then((respuesta) => {
      asignarFocoNombre(respuesta.foco);
      asignarErrorNombre(respuesta.error);
      asignarAllowNombre(respuesta.valida);
    });

    validarNombre(apellido, diccionario.errores).then((respuesta) => {
      asignarFocoApellido(respuesta.foco);
      asignarErrorApellido(respuesta.error);
      asignarAllowApellido(respuesta.valida);
    });

    validarTelefono(telefono, diccionario.errores).then((respuesta) => {
      asignarFocoTelefono(respuesta.foco);
      asignarErrorTelefono(respuesta.error);
      asignarAllowTelefono(respuesta.valida);
    });

    validarContrasenia(nuevaContrasena, diccionario.errores).then(
      (respuesta) => {
        asignarFocoContrasena(respuesta.foco);
        asignarAllowContrasena(respuesta.valida);
      }
    );

    if (nuevaContrasena.length > 7) {
      if (nuevaContrasena !== repetirNuevaContrasena) {
        asignarFocoConfirmarContrasena("error");
        asignarAllowConfirmarContrasena(false);
      } else {
        asignarFocoConfirmarContrasena("");
        asignarAllowConfirmarContrasena(true);
      }
    } else {
      asignarFocoConfirmarContrasena("error");
      asignarAllowConfirmarContrasena(false);
    }
  };

  const registrarUsuario = () => {
    asignarCargando(true);
    const { nombre, apellido, telefono, contrasenia } = usuarioRegistro;

    const variables = {
      contrasena: `${contrasenia}_1`,
      nombre,
      apellido,
      telefonoAsegurado: telefono,
      usuario: telefono,
    };

    registrarUsuarioGeneral({
      variables,
    });
  };

  const irARegistroSMS = () => {
    dispatch({
      type: "AGREGAR",
      valor: { vin: "", poliza: "", rfc: "" },
      indice: "datosIngresoPolizaCobranza",
    });

    const { telefono } = usuarioRegistro;
    // Limpiar el objeto usuarioRegistro
    asignarUsuarioRegistro({});

    history.push("/registro-usuario-sms", {
      telefono,
    });
  };

  useEffect(async () => {
    if (
      esVacio(focoNombre) &&
      esVacio(focoApellido) &&
      esVacio(focoTelefono) &&
      esVacio(focoContrasena) &&
      esVacio(focoConfirmarContrasena) &&
      esCierto(allowNombre) &&
      esCierto(allowApellido) &&
      esCierto(allowTelefono) &&
      esCierto(allowContrasena) &&
      esCierto(allowConfirmarContrasena)
    ) {
      registrarUsuario();
    }
  }, [allowNombre, allowTelefono, allowContrasena, allowConfirmarContrasena]);

  useEffect(() => {
    if (
      !loadingRegistroUsuario &&
      dataRegistroUsuarioGeneral &&
      dataRegistroUsuarioGeneral.seguridad_registraUsuarioGeneral
    ) {
      if (
        dataRegistroUsuarioGeneral?.seguridad_registraUsuarioGeneral?.completado
      ) {
        dispatch({
          type: "AGREGAR",
          valor: {
            telefono: usuarioRegistro.telefono,
            contrasenia: usuarioRegistro.contrasenia,
          },
          indice: "datosUsuarioCobranza",
        });

        asignarCargando(true);
        runSuccesLog(3);

        iniciarSesion({
          variables: {
            usuario: usuarioRegistro.telefono,
            contrasena: `${usuarioRegistro.contrasenia}_1`,
            latitud: "",
            longitud: "",
            plataforma: "HDI Contigo",
          },
        });
      } else {
        asignarCargando(false);
        if (
          dataRegistroUsuarioGeneral?.seguridad_registraUsuarioGeneral
            ?.mensaje === "Excepcion"
        ) {
          asignarMensajeAlerta(diccionario.errores.verificarCampos);
          asignarMostrarBarraAlerta(true);
        } else {
          if (
            dataRegistroUsuarioGeneral?.seguridad_registraUsuarioGeneral?.mensaje.includes(
              "existe"
            )
          ) {
            asignarMensajeAlerta(diccionario.errores.usuarioExiste);
            enfocarError("telefono");
          } else {
            asignarMensajeAlerta(
              dataRegistroUsuarioGeneral?.seguridad_registraUsuarioGeneral
                ?.mensaje
            );
            enfocarError(
              dataRegistroUsuarioGeneral?.seguridad_registraUsuarioGeneral
                ?.codigo
            );
          }
          asignarMostrarBarraAlerta(true);
        }
      }
    } else if (loadingRegistroUsuario) {
      asignarCargando(true);
    } else if (errorRegistroUsuario) {
      asignarCargando(false);
      asignarMensajeAlerta(diccionario.errores.errorGenerico);
      asignarMostrarBarraAlerta(true);
    } else if (!loadingRegistroUsuario) {
      asignarCargando(false);
    }
  }, [
    loadingRegistroUsuario,
    errorRegistroUsuario,
    dataRegistroUsuarioGeneral,
  ]);

  useEffect(() => {
    if (!loading && data) {
      if (
        data.iniciar_sesion &&
        data.iniciar_sesion.dato &&
        data.iniciar_sesion.dato.access_token
      ) {
        const diaHoraDeInicioDeSesion = moment().unix();
        const nuevoObjetoDeInicioDeSesion = JSON.parse(
          JSON.stringify(data.iniciar_sesion.dato)
        );

        nuevoObjetoDeInicioDeSesion.fechaYHoraDeInicioDeSesion =
          diaHoraDeInicioDeSesion;

        establecerCookie(
          nombreDeCookie,
          JSON.stringify(nuevoObjetoDeInicioDeSesion),
          {
            path: "/",
          }
        );

        dispatch({
          type: "AGREGAR",
          valor: {
            data: nuevoObjetoDeInicioDeSesion.NombreAsegurado,
          },
          indice: "NombreUsuarioPerfil",
        });

        dispatch({
          type: "AGREGAR",
          valor: {
            telefono: nuevoObjetoDeInicioDeSesion.Telefono,
            email: nuevoObjetoDeInicioDeSesion.CorreoElectronico,
            token: nuevoObjetoDeInicioDeSesion.access_token,
          },
          indice: "informacionContacto",
        });

        asignarCargando(false);
        irARegistroSMS();
      } else {
        asignarCargando(false);
        asignarMensajeAlerta(diccionario.errores.errorGenerico);
        asignarMostrarBarraAlerta(true);
      }
    } else if (loading) {
      asignarCargando(true);
    } else if (error) {
      asignarCargando(false);
    }
  }, [loading, error, data]);

  return (
    <EnvolvedorPantalla key={v4()}>
      {cargando ? <IndicadorCarga /> : null}
      <Encabezado
        titulo={diccionario.encabezado}
        funcionRegresar={() => {
          runCancelLog(3);
          history.push("/");
        }}
      />
      <Pantalla>
        <BarraAlerta
          etiqueta={mensajeAlerta}
          mostrarAlerta={mostrarBarraAlerta}
          manejarCierre={() => {
            asignarMostrarBarraAlerta(false);
          }}
          estilo="error"
          posicionAbsoluta
        />
        <Titulo id="titulo">{diccionario.titulo}</Titulo>
        <CampoTexto
          id="campoNombre"
          etiqueta={diccionario.etiquetaNombres}
          enCambio={alCambiarNombre}
          enDesenfocar={alDesenfocarNombre}
          foco={focoNombre}
          valor={valores.nombre}
          marcador="Ingresa tus nombres"
          numeroDeCaracteres={200}
          expresionRegular={/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/}
        />
        {errorNombre !== "" && (
          <MensajeError id="errorNombre">{errorNombre}</MensajeError>
        )}
        <SeparadorEspacio />
        <CampoTexto
          id="campoApellido"
          etiqueta={diccionario.etiquetaApellidos}
          enCambio={alCambiarApellido}
          enDesenfocar={alDesenfocarApellido}
          foco={focoApellido}
          valor={valores.apellido}
          marcador="Ingresa tus apellidos"
          numeroDeCaracteres={200}
          expresionRegular={/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/}
        />
        {errorNombre !== "" && (
          <MensajeError id="errorNombre">{errorApellido}</MensajeError>
        )}
        <SeparadorEspacio />
        <CampoTexto
          id="campoTelefono"
          etiqueta={diccionario.etiquetaTelefono}
          enCambio={alCambiarTelefono}
          enDesenfocar={alDesenfocarTelefono}
          foco={focoTelefono}
          valor={valores.telefono}
          marcador="555 456 5667"
          numeroDeCaracteres={10}
          expresionRegular={/^(\s*|\d+)$/}
        />
        {errorTelefono !== "" && (
          <MensajeError id="errorTelefono">{errorTelefono}</MensajeError>
        )}
        <SeparadorEspacio />
        <FormularioContrasena
          etiquetaContrasena="Nueva contraseña"
          etiquetaRepetirContrasena="Repetir contraseña"
          boton="Completar registro"
          accionBoton={hacerValidaciones}
          nuevaContrasena={valores.contrasenia}
          repetirNuevaContrasena={valores.confirmarContrasenia}
        />
      </Pantalla>
    </EnvolvedorPantalla>
  );
}
