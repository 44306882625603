import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useMenuService from "./services/menuService";

const rutasQueDisparanConsulta = [
  {
    propiedad: "misPagos",
    ruta: "/pagos",
  },
  {
    propiedad: "misReembolsos",
    ruta: "/mis-reembolsos",
  },
  {
    propiedad: "reportarSiniestro",
    ruta: "/mis-polizas?Reportar ",
  },
  {
    propiedad: "reportarSiniestro",
    ruta: "/polizas-siniestradas",
  },
  {
    propiedad: "misPolizas",
    ruta: "/mis-polizas",
  },
];

const AppListener = () => {
  const { obtenerOpcionesMenu, respuestaSolicitud, loadingMO } =
    useMenuService();
  const location = useLocation();
  const history = useHistory();
  const [consultado, setConsultado] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!consultado) {
      obtenerOpcionesMenu();
      setConsultado(true);
    }
  }, [consultado, obtenerOpcionesMenu]);

  useEffect(() => {
    const rutaCompleta = `${location.pathname}${location.search}`;
    if (
      rutasQueDisparanConsulta.some((item) => item.ruta === rutaCompleta) ||
      location.pathname === "/inicio" ||
      rutaCompleta !== "/"
    ) {
      obtenerOpcionesMenu();
    }
  }, [location]);

  useEffect(() => {
    if (!loadingMO) {
      if (!respuestaSolicitud(rutasQueDisparanConsulta, location)) {
        dispatch({
          type: "AGREGAR",
          indice: "mostrarCintaSinAcceso",
          valor: true,
        });
        history.push("/inicio");
      }
    }
  }, [loadingMO, respuestaSolicitud, location, history]);

  return null;
};

export default AppListener;
