import React, { useState, useEffect } from "react";
import {
  CajaResumenContenedor,
  Icono,
  Mensaje,
} from "./CajaResumenSinPoliza.styled";
import { ReactComponent as IconoPoliza } from "../../../recursos/iconos/ico_sin_poliza.svg";

const CajaResumenSinPoliza = () => {
  // eslint-disable-next-line
  const [valor, asignarValor] = useState(0);
  useEffect(() => {
    asignarValor(0);
  }, []);
  return (
    <CajaResumenContenedor>
      <Icono>
        <IconoPoliza width={60} className="icono" />
      </Icono>
      <Mensaje>
        <h3>No tienes ninguna póliza</h3>
        <p>Es necesario contar con al menos una póliza activa</p>
      </Mensaje>
    </CajaResumenContenedor>
  );
};

export default CajaResumenSinPoliza;
